import React from 'react';
import SoloGameSubpage from "../../../components/game/list/SoloGameSubpage";

const SinglesScreen = () => {


    return (
        <SoloGameSubpage type={"SINGLES"} title={"Singles"}/>
    )
};

export default SinglesScreen;
