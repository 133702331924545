export const LOGIN_ATTEMPT = "LOGIN_ATTEMPT"

export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGIN_FAILED = "LOGIN_FAILED"

export const REFRESH_ATTEMPT = "REFRESH_ATTEMPT"

export const REFRESH_SUCCESS = "REFRESH_SUCCESS"

export const REFRESH_FAILED = "REFRESH_FAILED"

export const LOGOUT = "LOGOUT"

export const UPDATE_DATA = "UPDATE_DATA"
