import React from 'react';
import SoloGameSubpage from "../../../components/game/list/SoloGameSubpage";

const EasyCapsGamesScreen = () => {

    return (
        <SoloGameSubpage type={"EASY_CAPS"} title={"Easy Caps"}/>
    )
};

export default EasyCapsGamesScreen;
